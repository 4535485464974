<template>
  <div v-if="canRender && allowedAccountId" class="log-viewer mh-100 h-100 d-flex flex-column">
    <!-- FILTERING ================================================================================================= -->
    <div>
      <b-card class="my-4">
        <h4 class="mb-4">Date/Time:</h4>
        <div class="d-flex justify-content-start align-items-end">
          <b-form-group class="mb-0 mr-3" label="From">
            <single-date-picker v-model="startTime"
                                time
                                opens="right"
                                :min-date="null" />
          </b-form-group>
          <b-form-group class="mb-0 mr-3" label="To">
            <single-date-picker v-model="endTime"
                                time
                                opens="right"
                                :min-date="null" />
          </b-form-group>
          <b-btn variant="success" class="mx-3" @click="fetchDataByDate">
            <fluency-icon type="loop" :class="{ 'rotating': loading }" />
            <template v-if="!loaded.logs">Load Results</template>
            <template v-if="loaded.logs">Refresh Logs</template>
          </b-btn>
        </div>
        <div class="d-flex mt-4">
          <div v-if="!loading && logs.length > 0" class="mr-5">
            <h4>Category:</h4>
            <fluency-loader v-if="loading" />
            <b-form-group v-else>
              <b-form-checkbox-group :checked="selectedCategories"
                                     @input="selectedCategories = [...$event]"
                                     :options="categories"
                                     switches
                                     stacked
                                     class="info-toggles" />
            </b-form-group>
          </div>
          <div v-if="!loading && logs.length > 0" class="mr-5">
            <h4>Partition:</h4>
            <fluency-loader v-if="loading" />
            <b-form-group v-else>
              <b-form-checkbox-group :checked="selectedPartitions"
                                     @input="selectedPartitions = [...$event]"
                                     :options="partitions"
                                     switches
                                     stacked
                                     class="secondary-toggles" />
            </b-form-group>
          </div>
          <div v-if="!loading && logs.length > 0" class="mr-5">
            <h4>Options:</h4>
            <fluency-loader v-if="loading" />
            <b-form-group v-else>
              <b-checkbox :checked="options.showClassNames" @input="options.showClassNames = $event" switch>Show Class Names</b-checkbox>
              <b-checkbox :checked="options.showErrorsOnly" @input="options.showErrorsOnly = $event" switch class="danger-toggles">Show Errors Only</b-checkbox>
            </b-form-group>
          </div>
          <div v-if="!loading && logs.length > 0" class="mr-5" style="min-width: 400px;">
            <h4>Text Filter:</h4>
            <fluency-loader v-if="loading" />
            <content-filter v-model="options.filter"
                            :min-length="2"
                            :highlight="options.filter.length > 2"
                            prepend-icon="search"
                            prepend-text="Logs"
                            placeholder="Enter a search term" />
          </div>
        </div>
      </b-card>
    </div>

    <!-- RESULTS =================================================================================================== -->
    <div class="overflow-auto my-4">
      <fluency-loader v-if="loading" />
      <template v-if="loaded.logs && !loading">
        <div v-if="filteredLogs.length < 1" class="p-1">
          <t-alert severity="warn">Nothing to display with those parameters. Try adjusting your filters or search in a different time range.</t-alert>
        </div>
        <table v-if="filteredLogs.length > 0">
          <thead>
            <tr>
              <th class="log-th">Category</th>
              <th class="log-th">Partition.</th>
              <th v-if="options.showClassNames" class="log-th">Class</th>
              <th class="log-th">Time</th>
              <th>Log</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(log, logKey) in filteredLogs" :key="logKey">
              <tr v-if="logKey <= options.rowLimit" >
                <td class="align-top"><b-badge variant="info" class="badge-full">{{log.category}}</b-badge></td>
                <td class="align-top"><b-badge variant="secondary" class="badge-full">{{log.partition}}</b-badge></td>
                <td v-if="options.showClassNames" class="align-top">
                  <b-badge :variant="(log.error) ? 'danger' : 'success'" class="badge-full">{{log.className}}</b-badge>
                </td>
                <td class="align-top text-nowrap">{{$moment.utc(log.localDateTime).local().format('YYYY-MM-DD HH:mm:ss')}}</td>
                <td class="align-top white-space-pre-line">{{log.log}}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </div>

    <!-- FOOTER ==================================================================================================== -->
    <div>
      <div class="logs-footer">
        <t-alert v-if="loaded.logs && !loading && filteredLogs.length > 0"
                   severity="success"
                   class="d-flex-center my-4 ">
          <b :class="classes.logFooterItem" >
            Showing
            <template v-if="filteredLogs.length > options.rowLimit">
              <span class="font-weight-bold">{{options.rowLimit}} of</span>
            </template>
            <template v-else>
              <span class="font-weight-bold">{{filteredLogs.length}} of</span>
            </template>

            <span class="font-weight-bold">{{logs.length}}</span>
          </b>

          <template v-if="filteredLogs.length > options.rowLimit">
            <span :class="classes.logFooterItem">||</span>
            <span :class="classes.logFooterItem">
              <b-btn variant="link"
                     class="p-0 font-weight-bold"
                     @click="loadMoreRows()">Load {{options.defaultRowLimit}} more rows</b-btn>
            </span>
          </template>
        </t-alert>
      </div>
    </div>
  </div>
  <div v-else-if="loaded.accounts && !canRender">
    <t-alert severity="warn" class="my-5">Unable to render Log Viewer. No AccountPlanId set.</t-alert>
  </div>
  <div v-else-if="loaded.accounts && !allowedAccountId">
    <t-alert severity="error" class="my-5">AccountPlanId is not associated with this user session.</t-alert>
  </div>
  <fluency-loader v-else />
</template>

<script>

import _delay from 'lodash.delay'
import _cloneDeep from 'lodash.clonedeep'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import ContentFilter from './forms/content-filter'
import SingleDatePicker from '@/components/common/dateSelector/components/singleDatePicker.vue'

export default {
  name: 'logViewer',
  components: { SingleDatePicker, ContentFilter, FluencyLoader },
  props: {
    accountPlanId: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      loading: false,
      loaded: {
        logs: false,
        accounts: false
      },
      logs: [],
      selectedCategories: [],
      selectedPartitions: [],
      currentIndexes: {
        log: null,
        file: null
      },
      classes: {
        logFooterItem: 'mx-3'
      },
      startTime: '',
      endTime: '',
      options: {
        defaultRowLimit: 50,
        rowLimit: 50,
        showClassNames: true,
        showErrorsOnly: false,
        filter: ''
      },
      partitions: [],
      categories: []
    }
  },
  watch: {
    categories: {
      handler (val) {
        this.$setCompat(this, 'selectedCategories', _cloneDeep(val))
      },
      deep: true,
      immediate: true
    },
    partitions: {
      handler (val) {
        this.$setCompat(this, 'selectedPartitions', _cloneDeep(val))
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    canRender () {
      return this.accountPlanId !== null && this.accountPlanId >= 0
    },
    allowedAccountId () {
      return this.$store.getters.accountsById?.has(this.accountPlanId)
    },
    filteredLogs () {
      return this.logs.filter(row => {
        if (row.category && !this.selectedCategories.includes(row.category)) {
          return false
        }

        if (row.partition && !this.selectedPartitions.includes(row.partition)) {
          return false
        }

        if (this.options.showErrorsOnly && row.error === false) {
          return false
        }

        if (this.options.filter && row.log.toLowerCase().indexOf(this.options.filter) === -1) {
          return false
        }

        return true
      })
    }
  },
  mounted () {
    this.setup()
  },
  beforeUnmount () {
    this.tearDown()
  },
  methods: {
    async setup () {
      await this.getAvailableAccounts()
      this.startTime = this.$moment().subtract(2, 'hours').format('YYYY-MM-DDTHH:mm:ss')
      this.endTime = this.$moment().format('YYYY-MM-DDTHH:mm:ss')
    },
    tearDown () {
      this.$setCompat(this, 'logs', [])
    },
    async getAvailableAccounts () {
      await Promise.all([
        this.$store.dispatch('loadAccountDetails', { skipMetrics: true })
      ])

      this.$setCompat(this.loaded, 'accounts', true)
    },
    fetchDataByDate () {
      this.$setCompat(this, 'loading', true)
      this.fetchData({ startingAt: this.startTime, endingAt: this.endTime })

      _delay(() => {
        this.$setCompat(this.loaded, 'logs', true)
        this.$setCompat(this, 'loading', false)
        this.$setCompat(this.options, 'rowLimit', this.options.defaultRowLimit)
      }, 750)
    },
    async fetchData (opts) {
      const { basePath, filePath, startingAt, endingAt } = opts
      const payload = {
        accountPlanId: this.accountPlanId,
        basePath,
        filePath,
        startingAt,
        endingAt
      }

      const response = await this.$res.fetch.readLogFile(payload)

      if (response) {
        this.$setCompat(this, 'logs', response)

        this.buildCategories()
        this.buildParitions()
      }
    },
    buildCategories () {
      const categories = []

      Object.entries(this.logs).forEach(([key, value]) => {
        if (value.category && !categories.includes(value.category)) {
          categories.push(value.category)
        }
      })

      categories.sort()

      this.$setCompat(this, 'categories', categories)
    },
    buildParitions () {
      const partitions = []

      Object.entries(this.logs).forEach(([key, value]) => {
        if (value.partition && !partitions.includes(value.partition)) {
          partitions.push(value.partition)
        }
      })

      partitions.sort()

      this.$setCompat(this, 'partitions', partitions)
    },
    loadMoreRows () {
      this.$setCompat(this.options, 'rowLimit', this.options.rowLimit + this.options.defaultRowLimit)
    }
  }
}
</script>

<style lang="scss" scoped>
.logs-footer {
  min-height: 25px;
}
.datepickerlabel {
  min-width: 40px;
}
.log-th {
  min-width: 125px;
}
</style>
